<template>
  <div class="home">
    <shared-header 
      :logo='require("@/assets/img/logo.png")'
    />
    <app-banner />
    <event-section 
      v-for="(event, index) in events" 
      :key="index"
      :id="event.slug"
      :events="event.events"
      :seasonName="event.name"
    />
    <shared-footer />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import SharedHeader from 'Components/Shared/SharedHeader.vue';
import AppBanner from 'Components/CalendarForGood/AppBanner.vue';
import EventSection from 'Components/CalendarForGood/EventSection'
import SharedFooter from 'Components/Shared/SharedFooter.vue';

export default {
  name: 'BaseLayout',

  components: {
    SharedHeader,
    AppBanner,
    EventSection,
    SharedFooter,
  },

  metaInfo() {
    const description = 'We offer fundraisers for all seasons and major holidays that nonprofits and their supporters can use to raise money for their causes.';
    const title = 'Raise money for all seasons!';
    return {
      title: 'Calendar for Good - Home',
      meta: [
        { vmid: 'description', name: 'description', content: description },
        { vmid: 'og:title', property: 'og:title', content: title },
        { vmid: 'og:description', name: 'og:description', content: description },
      ],
    };
  },

  computed: {
    ...mapState({
      events (state) {
        return state.events.data
      }
    })
  },
};
</script>
